/* You can add global styles to this file, and also import other style files */

$main-color: #1c7333;
$main-color-disabled: #4d8863;
$orange-color: #ef6700;
$white-color: #ffffff;
$black-color: #212529;
$gray-color: #5a5a5a;
$red-color: #cc0900;

html,
body {
  height: 100%;
  margin: 0;
  font-family: Calibri !important;
}
.btn:hover {
  color: #ffffff;
}

.mat-tooltip {
  transform-origin: unset;
  transform: scale(1);
}

mat-tooltip-component:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  border-width: 10px;
  border-style: solid;
  border-color: rgba(97, 97, 97, 0.9) transparent transparent;
  top: 46px;
  left: 45%;
}

:root {
  //Text
  --main-color: #1c7333;
  --main-color-disabled: #4d8863;
  --orange-color: #ef6700;
  --white-color: #ffffff;
  --black-color: #212529;
  --gray-color: #5a5a5a;
  --red-color: #cc0900;
  --primary-color: rgb(4, 106, 56);
}

//Widths
.w100 {
  width: 100%;
}

//Text Colors
.tcg {
  color: $main-color;
}

.tcd {
  color: $main-color-disabled;
}

.tco {
  color: $orange-color;
}

.tcb {
  color: $black-color;
}

.tcw {
  color: $white-color;
}

.tcgray {
  color: $gray-color;
}

.tcr {
  color: $red-color;
}

//Text align
.tc {
  text-align: center;
}

.tal {
  text-align: left;
}

.tar {
  text-align: right;
}

//Font Sizes
.fs-20 {
  font-size: 20px;
}

//Font Weights
.fw-500 {
  font-weight: 500 !important;
}

.fw-600 {
  font-weight: 600 !important;
}

//Background
.bcg {
  background: $main-color;
}

.bco {
  background: $orange-color;
}

//Margin
.m-0 {
  margin: -20px 0px 15px 0px !important;
}

.mt-2 {
  margin-top: 2em !important;
}

.mt-5 {
  margin-top: 5px !important;
}

.mb-0 {
  margin-bottom: 0px !important;
}

//Paddings
.ptb-10 {
  padding-top: 10px;
  padding-bottom: 10px;
}

//Button
.btn-center {
  margin: auto;
  display: block;
}

//DocuSign Broker
.swal2-broker {
  width: 95% !important;
  height: 95vh !important;
}

.toast-icon {
  color: $main-color !important;
  border-color: $main-color !important;
  &.swal2-icon.swal2-success [class^="swal2-success-line"] {
    background-color: $main-color !important;
  }
  &.swal2-success-circular-line-left {
    border-color: $main-color !important;
  }
}

.toast-title {
  color: $orange-color !important;
}

.iframe-container {
  overflow: hidden;
  // padding-top: 56.25%;
  // position: relative;
}

.iframe-container iframe {
  border: 0;
  border-radius: 5px;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0px;
  right: 0px;
  position: absolute;
  margin: auto;
}

.cancel-btn-doc {
  position: absolute !important;
  bottom: 35px !important;
  right: 20px !important;
  &:disabled {
    cursor: not-allowed;
  }
}

.div-notSopporting {
  width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-left: 40px;
  padding-right: 40px;
  position: fixed;
  top: 10px;
  .alert {
    max-width: 691px;
  }
}

// Insured component
.btn-policies-available {
  width: 45%;
  height: 45px;
  display: block;
  margin-left: auto;
  padding: 0px;
  background: $gray-color !important;
  opacity: 0.7;
}

.btn-policies-download {
  width: 74%;
  height: 45px;
  background: $orange-color;
  display: block;
  margin-left: auto;
  padding: 0px;
}
.hr-custom {
  width: 100%;
  border-top: 1px solid $gray-color;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 10px;
}

// documents component
.hr-custom {
  width: 100%;
  border-top: 1px solid $gray-color;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 0px;
}

// login component
.btn-custom {
  background: $orange-color;
  font-size: 18px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
}

.btn-custom:hover {
  background: $orange-color;
  color: #ffffff;
}
.custom-form-field {
  .mat-form-field-infix {
    border-top: 0px;
    padding-top: 0px;
    padding-bottom: 2px;
  }
}
